a.app-logo {
    color: white;
    text-decoration: none;
}
.new-crawl-btn {
    background-color: white !important;
}
.new-crawl-btn a {
    color: #333;
    text-decoration: none;
}

div.MuiChip-root.internal {
    height: 22px;
}
div.MuiChip-root.internal {
    border-color: #009688;
}
div.MuiChip-root.external {
    border-color: #be6db7;
}

.results-table th {
    max-width: 610px;
    overflow-wrap: break-word;
}

footer {
    z-index: 1;
}
